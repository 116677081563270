.form {
  background-color: hsla(120, 60%, 70%, 0.5);
}
/*
 * this class allows the buttons of a form
 * to be on opposite sides
 * 3.1.0 18.06.2021 < 12:30
 */
div.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.ui {
  font-size: medium;
}

.ui.form.mini input[type=text], .ui.mini.input {
  background: hsla(120, 60%, 70%, 0.7);
}
/*
 * The backButton id, is to allow me to have
 * the back button always to the left of the text
 * (18.06.2021 12:32) no need for the right:0 anymore,
 * since we have buttonGroup now
 * 2.1.0 11.12.2020 13:58
 */
#backButton {
  /*position: absolute;*/
  /*left: 0;*/
  /*width: 90px;*/
  text-align: center;
  /*z-index: 1;*/
  width: auto;
}
/*
 * The hangRight class, is to allow me to have
 * the back button always to the left of the text
 * 2.1.0 11.12.2020 13:58
 */
.hangRight {
  position: absolute;
  right: 0;
  margin-left: 91px;
  /*z-index: -1;*/
  overflow: auto;
}

/*
 * (12:32) no need for the right:0 anymore,
 * since we have buttonGroup now
 * 3.1.0 18.06.2021 12:15
 */
#submitButton {
  /*position: absolute;*/
  /*right: 0;*/
  text-align: center;
  /*z-index: 1;*/
  width: auto;
}

/*
 * To allow the dropdown to overflow beyond its containing table
 * 3.1.0 23.06.2021 13:48
 */
.tableDropdown {
  overflow: visible;
}

/*
 *
 * 3.1.0 29.06.2021 <09:55
 */
.ui.grid {
  justify-content: space-between; /*To stretch them accross the page*/
  margin-right: 2rem; /*Otherwise the rightmost column overflows*/
}

/*
 * Wanted the list to have a white background
 * 3.1.0 29.06.2021 <09:55
 */
.list {
  background-color: white;
}

/*
 * Otherwise it's very thin, and the text overflows the
 * white background
 * 3.1.0 29.06.2021 09:55
 */
.ui.grid>.column:not(.row), .ui.grid>.row>.column {
  width: auto;
  /*
   * In a column, I want the stuff to be vertically
   * ordered
   * 3.1.0 29.06.2021 09:55
   */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/*
 * In order for buttons in a column,
 * to have proper width, we must override
 * .btn's width property
 * 3.1.0 29.06.2021 12:02
 */
/*.column:not(.row)>.btn, .row>.column>.btn {*/
/*.column>.btn {*/
/*.ui.grid>.column:not(.row) .btn, .ui.grid>.row>.column .btn {*/
.column .btn {
  width: auto;
}
