body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("mmmt_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  /*background-size: cover;*/
  background-size: cover;
  background-attachment: fixed;
  /*overflow: visible;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  /*position: fixed;*/
  border: solid black;
  align-self: center;
  width: 50%;
}

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  padding-top: 10%;
  padding-bottom: 10%;
  /*background-image: url("Lucy.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
  position: relative;
}

p {
  background-color: rgba(0, 128, 200, 0.5);
}

/*
 * The container class, is to allow me to have
 * the back button always to the left of the text
 * 2.1.0 11.12.2020 13:56
 */
.container {
  position: absolute;
  margin: auto;
  width: 60%;
  /*padding: 0 70px;*/
}

.dashboard > p.hangRight {
  margin-bottom: 0;
  align-self: flex-end;
}

/*
 * The default container was screwing things up
 * and has !important in it.
 * I was forced to do width:100% with !important because of it
 * 3.1.0 17.06.2021 14:16
 */
.ui.container.width-override {
  width: 100%!important;
}

.ui.center-stage {
  height: 75%;
  /*margin-top: 20%;
  margin-bottom: 20%;*/
  padding-top: 10%;
  padding-bottom: 10%;
}

.ui.center-stage.container {
  height: 100%;
  /*margin-top: 20%;
  margin-bottom: 20%;*/
  padding-top: 20%;
  padding-bottom: 20%;
}

.ui.header {
  align-self: center;
}
